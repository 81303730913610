<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    width="95%"
    fullscreen
    :title="$t('customer.orders.reviewOrder')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-row class="pa-10">
        <v-col :lg="6">
          <div class="text-h6 mb-4">
            {{ $t('customer.orders.orderDetailsForm') }}
          </div>
          <v-card flat tile :loading="loading">
            <v-form class="position-relative" :disabled="loading">
              <v-row
                style="height: calc(100vh - 210px); padding-bottom: 65px"
                class="overflow-y-auto overflow-x-hidden"
              >
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.orderNumber')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.orderNumber')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="orderLocal.orderNumber"
                        type="number"
                        :error-messages="errors"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.orderStatus')"
                    required
                    class="mb-4"
                  >
                    <div class="d-flex align-center" style="column-gap: 5px">
                      <div class="text-body-1">
                        {{ orderStatusText[orderLocal.status] }}
                      </div>
                      <v-menu offset-y :nudge-bottom="10">
                        <template v-slot:activator="{ on, attrs }">
                          <cz-button
                            :icon-src="mdiInformation"
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                            text
                            :readonly="readOnlyMode"
                          />
                        </template>
                        <v-card :max-width="300" v-if="orderStatusHint">
                          <v-card-text class="text-pre-wrap">
                            {{ orderStatusHint }}
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.vendorId')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.vendorId')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        :value="
                          selectedVendorLocal
                            ? selectedVendorLocal._id
                            : orderLocal.vendorId
                        "
                        type="number"
                        :error-messages="errors"
                        @change="onVendorChange"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field :label="$t('customer.orders.vendorName')">
                    <div v-if="selectedVendorLocal" class="text-body-1">
                      {{ selectedVendorLocal.businessName }}
                    </div>

                    <div
                      v-else
                      class="d-flex align-center"
                      style="column-gap: 10px"
                    >
                      <div
                        class="negative--text text-body-1 font-weight-semibold"
                      >
                        {{ $t('customer.orders.vendorNotFound') }}
                      </div>
                      <v-menu offset-y :nudge-bottom="10">
                        <template v-slot:activator="{ on, attrs }">
                          <cz-icon
                            :src="mdiInformation"
                            color="primary"
                            v-on="on"
                            v-bind="attrs"
                          />
                        </template>
                        <v-card :max-width="350">
                          <v-card-text class="text-pre-wrap">
                            {{ $t('customer.orders.vendorNotFoundHint') }}
                          </v-card-text>
                          <v-card-actions>
                            <cz-button
                              text
                              color="primary"
                              :title="$t('customer.orders.inviteVendor')"
                              @click="$emit('invite-vendor')"
                            />
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </cz-form-field>
                </v-col>

                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.issueDate')"
                    required
                  >
                    <validation-provider
                      rules="required|date_format:dd/MM/yyyy"
                      :name="$t('customer.orders.issueDate')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="orderLocal.issueDate"
                        v-mask="'##/##/####'"
                        placeholder="dd/mm/yyyy"
                        :error-messages="errors"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.orderAuthorizer')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.orderAuthorizer')"
                      v-slot="{ errors }"
                    >
                      <cz-autocomplete
                        v-model="orderLocal.authorizerUserId"
                        :error-messages="errors"
                        :items="orderAuthorizers"
                        item-text="fullName"
                        item-value="_id"
                        dense
                        :placeholder="
                          $t('customer.orders.selectOrderAuthorizer')
                        "
                        :readonly="readOnlyMode"
                        clearable
                      >
                        <template #item="{ item }">
                          <v-list-item-avatar>
                            <cz-avatar
                              :size="35"
                              color="primary"
                              :name="item.fullName"
                              initials-color="white"
                              initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
                            />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.fullName }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </cz-autocomplete>
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.netAmount')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.netAmount')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="orderLocal.netAmount"
                        type="number"
                        :error-messages="errors"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.vatAmount')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.vatAmount')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="orderLocal.vatAmount"
                        type="number"
                        :error-messages="errors"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.totalAmount')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.totalAmount')"
                      v-slot="{ errors }"
                    >
                      <cz-input
                        v-model="orderLocal.totalAmount"
                        type="number"
                        :error-messages="errors"
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :lg="6" :md="6" :sm="12">
                  <cz-form-field
                    :label="$t('customer.orders.currency')"
                    required
                  >
                    <validation-provider
                      rules="required"
                      :name="$t('customer.orders.currency')"
                      v-slot="{ errors }"
                    >
                      <cz-currency-picker
                        v-model="orderLocal.currency"
                        :items="currencyTypes"
                        :error-messages="errors"
                        outlined
                        dense
                        :readonly="readOnlyMode"
                      />
                    </validation-provider>
                  </cz-form-field>
                </v-col>
                <v-col :cols="12">
                  <cz-form-field :label="$t('customer.orders.shortDesc')">
                    <cz-input
                      v-model="orderLocal.description"
                      dense
                      :placeholder="$t('customer.orders.shortDescInfo')"
                      :max-length="75"
                      :counter="75"
                      :readonly="readOnlyMode"
                    />
                  </cz-form-field>
                </v-col>
              </v-row>
              <v-footer
                absolute
                class="d-flex justify-space-between elevation-1"
                style="column-gap: 10px"
                color="white"
                :height="60"
                v-if="!readOnlyMode"
              >
                <div class="d-flex" style="column-gap: 10px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <cz-button
                        :title="$t('customer.orders.saveAndOpenOrder')"
                        color="positive"
                        :disabled="invalid"
                        v-on="on"
                        :dark="!invalid"
                        :icon-src="mdiCheckAll"
                        v-bind="attrs"
                        :loading="loading"
                        @click="$emit('on-save', orderLocal)"
                      />
                    </template>
                    <span>{{ $t('customer.orders.saveAndOpenHint') }}</span>
                  </v-tooltip>
                  <v-tooltip top v-if="orderChanged">
                    <template v-slot:activator="{ on, attrs }">
                      <cz-button
                        :title="$t('customer.orders.revertChanges')"
                        color="secondary"
                        v-on="on"
                        outlined
                        :icon-src="mdiUndo"
                        v-bind="attrs"
                        @click="onRevert"
                      />
                    </template>
                    <span>{{ $t('customer.orders.revertChangesHint') }}</span>
                  </v-tooltip>
                </div>
                <cz-button
                  v-if="!loading"
                  :title="$t('customer.orders.cancelOrder')"
                  color="negative"
                  @click="$emit('on-cancel', orderLocal)"
                  text
                />
              </v-footer>
            </v-form>
          </v-card>
        </v-col>
        <v-col :lg="6">
          <div class="text-h6 mb-4">
            {{ $t('customer.orders.orderDocument') }}
          </div>
          <v-card height="calc(100vh - 210px)" style="overflow-y: auto">
            <pdf-viewer :source="order.orderDocument.url" />
          </v-card>
        </v-col>
      </v-row>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzButton,
  CzAvatar,
  CzIcon,
  CzCurrencyPicker
} from '@/components';
import PdfViewer from 'vue-pdf-embed/dist/vue2-pdf-embed';
import cloneDeep from 'lodash.clonedeep';
import { parse, format, isValid } from 'date-fns';
import { typesMixin } from '@/shared/mixins';
import { mdiInformation, mdiUndo, mdiCheckAll } from '@mdi/js';
import OrderStatus from '@/shared/types/OrderStatus';
import currencyTypes from '@/shared/types/CurrencyTypes';
import isEqual from 'lodash.isequal';
export default {
  name: 'ReviewOrderDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzAutocomplete,
    CzCurrencyPicker,
    PdfViewer,
    CzButton,
    CzAvatar,
    CzIcon
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    orderAuthorizers: {
      type: Array,
      default: () => []
    },
    selectedVendor: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [typesMixin],
  created() {
    this.orderLocal = cloneDeep(this.order);
    this.selectedVendorLocal = cloneDeep(this.selectedVendor);
    if (this.orderLocal.issueDate) {
      this.orderLocal.issueDate = format(
        new Date(this.orderLocal.issueDate),
        'dd/MM/yyyy'
      );
    }
  },
  computed: {
    readOnlyMode() {
      return (
        this.orderLocal.status !== OrderStatus.NEW &&
        this.orderLocal.status !== OrderStatus.NEW_PREDICTED
      );
    },
    orderStatusHint() {
      if (this.orderLocal.status !== OrderStatus.NEW_PREDICTED) {
        return null;
      }

      return this.$t('customer.orders.status.new_predicted_hint');
    },
    authorizersItems() {
      return [];
    },
    vendorExsists() {
      return !!this.orderLocal.vendor;
    },
    orderChanged() {
      return !isEqual(this.order, this.orderLocal);
    }
  },
  data() {
    return {
      currencyTypes,
      mdiInformation,
      mdiCheckAll,
      mdiUndo,
      pdf: null,
      orderLocal: null,
      selectedVendorLocal: null,
      issueDate: null
    };
  },
  methods: {
    onVendorChange(value) {
      this.$emit('vendor-changed', value, (vendor) => {
        this.selectedVendorLocal = vendor;
        this.orderLocal.vendorId = vendor._id;
      });
    },
    onRevert() {
      this.orderLocal = cloneDeep(this.order);
      this.selectedVendorLocal = cloneDeep(this.selectedVendor);
    }
  }
};
</script>

<style></style>
